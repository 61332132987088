<template>
  <div class="studentList">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input
        class="input"
        v-model="input"
        placeholder="输入学生姓名"
      ></el-input>
      <el-select class="input" v-model="class_id" placeholder="请选择班级">
        <el-option
          v-for="item in classData"
          :key="item.shop_class_id"
          :label="item.class_name"
          :value="item.shop_class_id"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="searchInp">查询</el-button>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="userName"
          label="学生姓名"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="telNumber"
          label="手机号"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="lesson_name"
          label="报名课程"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column prop="created" label="报名时间"></el-table-column>
        <el-table-column
          prop="class_name"
          label="所属班级"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="chain_name"
          label="所属机构"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-select
              class="form-input"
              size="mini"
              @change="addClassStudent($event, scope.row.shop_class_student_id)"
              v-model="scope.row.shop_class_id"
              placeholder="请选择班级"
            >
              <el-option
                v-for="item in classData"
                :key="item.shop_class_id"
                :label="item.class_name"
                :value="item.shop_class_id"
              ></el-option>
            </el-select>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <MyPagination
      :total="total"
      :pageSize="query_info.limit"
      @changePage="changePage"
      :currentPage="query_info.page"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getClassList,
  getStudentList,
  shopStudentStatus,
  shopDelStudent,
} from "../../../request/api";
import MyPagination from "../../../components/MyPagination.vue";

export default {
  inject: ["reload"],
  components: {
    MyPagination,
  },
  data() {
    return {
      input: "",
      class_id: "",
      classData: [],
      tableData: [],
      query_info: {
        chain_id: "",
        limit: 10,
        page: 1,
      },
      total: 0,
      loading: false,
    };
  },
  computed: {
    ...mapState("m_login", ["loginData"]),
  },
  methods: {
    async getStudent() {
      try {
        this.loading = true;
        const res = await getStudentList(
          this.query_info.chain_id,
          this.query_info.limit,
          this.query_info.page,
          this.input,
          this.class_id
        );
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.tableData = res.data.data.studentList;
        this.total = res.data.data.totalNumber;
      } finally {
        this.loading = false;
      }
    },
    // 搜索按钮
    searchInp() {
      this.query_info.page = 1;
      this.tableData = [];
      this.getStudent();
    },
    // 删除操作
    handleDelete(row) {
      this.$confirm("是否删除该学生？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let postData = {
            shop_class_student_id: row.shop_class_student_id,
          };
          const res = await shopDelStudent(postData);
          if (res.status !== 200 || res.data.code !== "1000") {
            this.$message.error(res.data.msg);
            return;
          }
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.reload();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 小列表
    async getClass() {
      const res = await getClassList(this.query_info.chain_id, 999, 1, "");
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.classData = res.data.data.classList;
    },
    // 选中值时触发
    async addClassStudent(shop_class_id, shop_class_student_id) {
      let postData = {
        shop_class_id: shop_class_id,
        shop_class_student_id: shop_class_student_id,
      };
      const res = await shopStudentStatus(postData);
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.getStudent();
      this.$message({
        type: "success",
        message: "修改成功！",
      });
      this.reload();
    },
    // 自定义事件 取到分页的页码
    changePage(page) {
      this.query_info.page = page;
      this.tableData = [];
      this.getStudent();
    },
  },
  created() {
    this.query_info.chain_id = this.loginData.chain_id;
  },
  mounted() {
    this.getClass();
    this.getStudent();
  },
};
</script>

<style lang="less" scoped>
.studentList {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
      margin-right: 10px;
    }
  }
  .wrapper {
    margin: 20px 0;
    .form-input {
      width: 140px;
      margin-right: 10px;
    }
  }
}
</style>
